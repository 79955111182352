


































































































import { historial_patologias_paciente } from "@/shared/dtos/historial_patologias_paciente";
import historial_patologias_pacienteModule from "@/store/modules/historial_patologias_paciente-module";
import { Component, Vue, Prop } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { DxDateBox } from "devextreme-vue";
import { patologia } from "@/shared/dtos/patologia";
import { arrayhistorial_patologias_paciente } from "@/shared/dtos/arrayhistorial_patologias_paciente";
import { UtilsEncript } from "@/utils/utils-encript";
import { UtilsNotify } from "@/utils/utils-notify";
import { AsisFechasDto } from "@/shared/dtos/AsistentesDtos/AsisFechasDto";
@Component({
  components: {
    PacienteFichaOpciones: () => import("./paciente-ficha-opciones.vue"),
    DxDateBox,
    patologiasListaSelect: () =>
      import("@/views/patologias/patologiasListaSelect.vue"),
    PeriodoFechas: () => import("@/components/PeriodoFechas/periodo-fechas.vue")
  }
})
export default class pacienteFichaFormulario extends Vue {
  public id_paciente!: number;
  public search: string = "";
  public dialog: boolean = false;
  public dialog_patologias: boolean = false;
  public dialog_asistente_fecha: boolean = false;
  public patologia_paciente: historial_patologias_paciente = new historial_patologias_paciente();
  public textoNotas: string = "";
  public patologiasseleccioandas: patologia[] = [];
  public DateDesde: Date = new Date();
  public DateHasta: Date = new Date();

  public async created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id)
      );
    }
    historial_patologias_pacienteModule.get_patologias_paciente(
      this.id_paciente ? this.id_paciente : 0
    );
  }

  public get mas_opciones() {
    return [
      { title: "Imprimir patologias activas", id: 0 },
      { title: "Imprimir patologias activas entre periodo de fechas", id: 1 }
    ];
  }

  public get patologias() {
    return historial_patologias_pacienteModule.historial_patologias_pacientes;
  }

  public getdescripcion(str: string) {
    str = UtilsString.cortarStringLargo(str, 10);
    if (str.length === 0) {
      str = "No hay notas";
    }
    return str;
  }

  public get headers() {
    return [
      {
        text: "Nombre",
        align: "start",
        value: "nombre_patologia"
      },
      { text: "Activa", value: "lotiene" },
      { text: "Notas", value: "descripcion_patologia" },
      { text: "Fecha", value: "fecha" },
      { text: "Acciones", value: "actions", sortable: false }
    ];
  }
  public AceptarNotas() {
    this.patologia_paciente.descripcion_patologia = this.textoNotas;
    this.dialog = false;
    this.ItemActualizado(this.patologia_paciente);
  }
  public VerNotas(item: historial_patologias_paciente) {
    this.textoNotas = item.descripcion_patologia;
    this.patologia_paciente = item;
    this.dialog = true;
  }
  public async AceptarPatologias(patologias: patologia[]) {
    let histpatos: arrayhistorial_patologias_paciente = new arrayhistorial_patologias_paciente();
    histpatos.historial_Patologias_PacienteDtos = [];
    for (let i = 0; i < patologias.length; i++) {
      let histpato: historial_patologias_paciente = new historial_patologias_paciente();
      histpato.fecha = new Date();
      histpato.id = 0;
      histpato.lotiene = true;
      histpato.descripcion_patologia = "";
      histpato.id_paciente = this.id_paciente;
      histpato.id_patologia = patologias[i].id;
      histpatos.historial_Patologias_PacienteDtos.push(histpato);
    }
    await historial_patologias_pacienteModule.guardarhistorial_patologias_paciente(
      histpatos
    );
    await historial_patologias_pacienteModule.get_patologias_paciente(
      this.id_paciente
    );
  }
  public deletePatologia(historial_patologia: historial_patologias_paciente) {
    //@ts-ignore
    this.$vs.dialog({
      type: "confirm",
      color: "danger",
      title: `Confirme`,
      acceptText: "Aceptar",
      cancelText: "Cancelar",
      text:
        "¿Seguro que quiere marcar esta patología como inactiva para el paciente?",
      accept: () => this.acceptAlertModificar(historial_patologia)
    });
  }
  public acceptAlertModificar(
    historial_patologia: historial_patologias_paciente
  ) {
    historial_patologia.lotiene = false;
    historial_patologias_pacienteModule.modificarhistorial_patologias_paciente(
      historial_patologia
    );
    UtilsNotify.NotificacionPrimary(
      "Patología",
      "La patología se ha marcada como inactiva."
    );
  }
  public ItemActualizado(historial_patologia: historial_patologias_paciente) {
    historial_patologias_pacienteModule.modificarhistorial_patologias_paciente(
      historial_patologia
    );
  }
  public accion_opciones(item: any) {
    switch (item.id) {
      case 0:
        historial_patologias_pacienteModule.get_patologias_paciente_pdf_all(
          this.id_paciente
        );
        break;
      case 1:
        this.dialog_asistente_fecha = true;
        break;
    }
  }
  public printpdffechas() {
    let asis: AsisFechasDto = new AsisFechasDto();
    asis.id = this.id_paciente;
    asis.fecha_desde = this.DateDesde;
    asis.fecha_hasta = this.DateHasta;
    this.dialog_asistente_fecha = false;
    historial_patologias_pacienteModule.get_patologias_paciente_pdf_fechas(
      asis
    );
  }
}
