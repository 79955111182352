import { BaseDto } from './base-dto';

export class historial_patologias_paciente extends BaseDto {
    public fecha!:Date;
    public lotiene !: boolean;
    public descripcion_patologia !: string;
    public nombre_patologia !: string;
    public tipo_patologia !: string;
    public id_paciente !: number;
    public id_patologia !: number;
 } 
