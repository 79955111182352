var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PacienteFichaOpciones',{attrs:{"id_paciente":_vm.id_paciente}}),_c('vs-card',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.patologias,"search":_vm.search,"no-data-text":"No hay patologías activas en el paciente, para añadir patologías haga click en el botón añadir patología. Seleccione las patologías que tenga el paciente y acepte"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Asigne las patologías del paciente o consultelas")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","color":"primary","dark":""}},on),[_vm._v("Opciones")])]}}])},[_c('v-list',_vm._l((_vm.mas_opciones),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.accion_opciones(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"primary","dark":""},on:{"click":function($event){_vm.dialog_patologias=true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add_circle_outline")]),_vm._v("Añadir patología ")],1),_c('v-text-field',{staticClass:"ml-4",attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.lotiene",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.lotiene),callback:function ($$v) {_vm.$set(item, "lotiene", $$v)},expression:"item.lotiene"}})]}},{key:"item.descripcion_patologia",fn:function(ref){
var item = ref.item;
return [_c('label',{on:{"click":function($event){return _vm.VerNotas(item)}}},[_vm._v(_vm._s(_vm.getdescripcion(item.descripcion_patologia)))])]}},{key:"item.fecha",fn:function(props){return [_c('DxDateBox',{attrs:{"value":props.item.fecha,"apply-value-mode":"useButtons","onValueChanged":_vm.ItemActualizado(props.item)},on:{"update:value":function($event){return _vm.$set(props.item, "fecha", $event)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deletePatologia(item)}}},[_vm._v("mdi-delete")])]}}])}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Notas de la patología")]),_c('v-card-text',[_vm._v(" Escriba aquí las notas de la patología. "),_c('v-textarea',{model:{value:(_vm.textoNotas),callback:function ($$v) {_vm.textoNotas=$$v},expression:"textoNotas"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.AceptarNotas()}}},[_vm._v("Aceptar")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog_patologias),callback:function ($$v) {_vm.dialog_patologias=$$v},expression:"dialog_patologias"}},[_c('patologiasListaSelect',{attrs:{"id_paciente":_vm.id_paciente,"dialog":_vm.dialog_patologias,"seleccioandas":_vm.patologiasseleccioandas},on:{"update:dialog":function($event){_vm.dialog_patologias=$event},"Aceptado":function($event){return _vm.AceptarPatologias($event)}}})],1),_c('PeriodoFechas',{attrs:{"dialog_seleccion_fechas":_vm.dialog_asistente_fecha,"desde":_vm.DateDesde,"hasta":_vm.DateHasta},on:{"update:dialog_seleccion_fechas":function($event){_vm.dialog_asistente_fecha=$event},"update:desde":function($event){_vm.DateDesde=$event},"update:hasta":function($event){_vm.DateHasta=$event},"Aceptado":function($event){return _vm.printpdffechas()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }