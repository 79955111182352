import { store } from '@/store/store';
import { historial_patologias_paciente } from '@/shared/dtos/historial_patologias_paciente';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { arrayhistorial_patologias_paciente } from '@/shared/dtos/arrayhistorial_patologias_paciente';
import { UtilsFiles } from '@/utils/utils-files';
import { AsisFechasDto } from '@/shared/dtos/AsistentesDtos/AsisFechasDto';

@Module({
    namespaced: true,
    name: 'historial_patologias_pacienteModule',
    store,
    dynamic: true
})
class historial_patologias_pacienteModule extends VuexModule {
    public historial_patologias_pacientes: historial_patologias_paciente[] = [];

    @Action({ commit: 'onGethistorial_patologias_paciente' })
    public async gethistorial_patologias_paciente(id: any) {
        return await ssmHttpService.get(API.historial_patologias_paciente + '/' + id);
    }

    @Action({ commit: 'onGetdescargapdf' })
    public async get_patologias_paciente_pdf_all(id_paciente: any) {
        return await ssmHttpService.get(API.historial_patologias_paciente + '/pdf_hist_patologias_tiene_all/' + id_paciente);
    }

    @Action({ commit: 'onGetdescargapdf' })
    public async get_patologias_paciente_pdf_fechas(asistentedto: AsisFechasDto) {
        return await ssmHttpService.post(API.historial_patologias_paciente + '/pdf_hist_patologias_tiene_fechas/', asistentedto.toJson());
    }


    @Action({ commit: 'onGethistorial_patologias_paciente' })
    public async get_patologias_paciente(id_paciente: any) {
        return await ssmHttpService.get(API.historial_patologias_paciente + '/PatosPaci/' + id_paciente);
    }


    @Action
    public async guardarhistorial_patologias_paciente(historial_patologias_paciente: arrayhistorial_patologias_paciente) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.historial_patologias_paciente, historial_patologias_paciente.toJson());

        //this.gethistorial_patologias_paciente(historial_patologias_paciente[0].id_paciente);
    }

    @Action({ commit: 'onDeleteElement' })
    public async modificarhistorial_patologias_paciente(historial_patologias_paciente: historial_patologias_paciente) {
        await ssmHttpService.put(API.historial_patologias_paciente + '/' + historial_patologias_paciente.id, historial_patologias_paciente, false);
        //this.gethistorial_patologias_paciente(historial_patologias_paciente[0].id_paciente);
        return historial_patologias_paciente;
    }

    @Mutation
    public onGethistorial_patologias_paciente(res: any) {
        if (res === undefined || res === null) {
            this.historial_patologias_pacientes = [];
            return;
        }
        if (res.historial_Patologias_PacienteDtos === undefined || res.historial_Patologias_PacienteDtos === null) {
            this.historial_patologias_pacientes = [];
            return;
        }
        this.historial_patologias_pacientes = res.historial_Patologias_PacienteDtos;
    }

    @Mutation
    public onDeleteElement(res: historial_patologias_paciente) {
        for (let i = 0; i < this.historial_patologias_pacientes.length; i++) {
            const element = this.historial_patologias_pacientes[i];
            if (element.id === res.id && res.lotiene === false) {
                this.historial_patologias_pacientes.splice(i, 1);
            }
        }
    }

    @Mutation
    public async onGetdescargapdf(response: any) {
        UtilsFiles.CreateURLFromBase64AndOpen(response, 'application/pdf');
    }
}
export default getModule(historial_patologias_pacienteModule);